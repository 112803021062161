import type { InvitationRequest } from '@/domain/invite';
import type { PropertyGroup } from '@/domain/property-groups';
import type {
  ChangePasswordRequest,
  UpdateMeRequest,
  UpdateMyPasswordRequest,
  User,
  UserCallCenterAgentSkillRequest,
  UserSearchParams,
  Worker,
} from '@/domain/user';
import { api } from '@/utils/configuration';
import { crudService, getAxiosFetcher } from '@/utils/request';
import type { Operation } from 'fast-json-patch';

export const UserService = {
  search: getAxiosFetcher<User[], [UserSearchParams]>((params) => [api.USER, { params }]),

  findById: getAxiosFetcher<User, [number]>((id) => [`${api.USER}/${id}`]),

  update: (id: number, user: Partial<User>, version: number) =>
    crudService.put<User>(user, `${api.USER}/${id}?version=${version}`),

  patch: (id: number, operations: Operation[], version: number) =>
    crudService.patch<User>(operations, `${api.USER}/${id}?version=${version}`),

  updateMyPassword: (updateMyPasswordRequest: UpdateMyPasswordRequest, version: number) =>
    crudService.put<void>(updateMyPasswordRequest, `${api.USER}/me/password?version=${version}`),

  me: getAxiosFetcher<User, []>(() => [`${api.USER}/me`]),

  updateMe: (updateMeRequest: UpdateMeRequest): Promise<User> =>
    crudService.put<User>(updateMeRequest, `${api.USER}/me`),

  changePassword: (changePasswordRequest: ChangePasswordRequest, version: number) =>
    crudService.put<void>(changePasswordRequest, `${api.USER}/password?version=${version}`),

  delete: (id: number) => crudService.delete<void>(`${api.USER}/${id}`),

  inviteUsers: (invites: InvitationRequest) => crudService.post<void>(invites, api.USER),

  invite: (id: number) => crudService.post<void>({}, `${api.USER}/${id}/invite`),

  findPropertyGroups: getAxiosFetcher<PropertyGroup[], [number]>((userId) => [
    `${api.USER}/${userId}/property-groups`,
  ]),

  bulkPropertyGroup: (body: number[], userId: number): Promise<PropertyGroup> =>
    crudService.put<PropertyGroup>(body, `${api.USER}/${userId}/property-groups/bulk`),

  deletePropertyGroup: (userId: number, propertyGroupId: number) =>
    crudService.delete<void>(`${api.USER}/${userId}/property-groups/${propertyGroupId}`),

  findCallCenterAgent: getAxiosFetcher<Worker, [number]>((id) => [
    `${api.USER}/${id}/call-center-agent`,
  ]),

  createContactCenterAgent: (userId: number): Promise<User> =>
    crudService.post<User>({}, `${api.USER}/${userId}/call-center-agent`),

  addSkill: (userId: number, request: UserCallCenterAgentSkillRequest): Promise<void> =>
    crudService.post<void>(request, `${api.USER}/${userId}/call-center-agent/skills`),

  updateSkill: (userId: number, request: UserCallCenterAgentSkillRequest): Promise<void> =>
    crudService.put<void>(request, `${api.USER}/${userId}/call-center-agent/skills`),

  deleteSkill: (userId: number, skillName: string): Promise<void> =>
    crudService.delete<void>(`${api.USER}/${userId}/call-center-agent/skills/${skillName}`),
};
