import type { New, PropifyEntity } from '@/domain/base';
import type { Resident, ResidentStatus } from '@/domain/resident';
import type { Unit } from '@/domain/unit';
import type { BigDecimal, MonetaryAmount } from '@propify/components';
import type { NameSuffix } from './applications';
import type { PartyRole, Person } from './person';
import type { Occupant, Pet, Vehicle } from './unit-applications';

export enum UtilityResponsiblePartyRoleType {
  PROPERTY_MANAGER = 'PROPERTY_MANAGER',
  RESIDENT = 'RESIDENT',
  DOES_NOT_APPLY = 'DOES_NOT_APPLY',
}

export enum UtilityType {
  ELECTRICITY = 'ELECTRICITY',
  WATER = 'WATER',
  GAS = 'GAS',
  INTERNET_CABLE = 'INTERNET_CABLE',
  SEWAGE = 'SEWAGE',
  GARBAGE = 'GARBAGE',
}

export enum LeaseStatus {
  DRAFT = 'DRAFT',
  TERMS_PROPOSED = 'TERMS_PROPOSED',
  TERMS_ACCEPTED = 'TERMS_ACCEPTED',
  TERMS_REJECTED = 'TERMS_REJECTED',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  CANCELLED = 'CANCELLED',
}

export enum LeaseChargeType {
  RENT = 'RENT',
  ELEVEN_MONTHS_RENT_MINUS_PROMOTION = 'ELEVEN_MONTHS_RENT_MINUS_PROMOTION',
  EXTRA_MONTH_RENT = 'EXTRA_MONTH_RENT',
  TWO_MONTHS_RENT = 'TWO_MONTHS_RENT',
  SIX_MONTHS_RENT_AND_FEES = 'SIX_MONTHS_RENT_AND_FEES',
  AMENITY_FEE = 'AMENITY_FEE',
  CONDITIONAL_ACCEPTANCE = 'CONDITIONAL_ACCEPTANCE',
  HOA = 'HOA',
  PET = 'PET',
  POOL = 'POOL',
  PROCESSING_FEE = 'PROCESSING_FEE',
  REMAINING_LEASE_BALANCE_YEAR_UP_FRONT = 'REMAINING_LEASE_BALANCE_YEAR_UP_FRONT',
  RESIDENT_BENEFIT_PACKAGE = 'RESIDENT_BENEFIT_PACKAGE',
  SECURITY_DEPOSIT = 'SECURITY_DEPOSIT',
  SOLAR_PANEL = 'SOLAR_PANEL',
  YEAR_IN_FULL_MINUS_PROMOTION = 'YEAR_IN_FULL_MINUS_PROMOTION',
  YEAR_UP_FRONT = 'YEAR_UP_FRONT',
  HOLDING_FEE = 'HOLDING_FEE',
  MOVE_IN_FEE = 'MOVE_IN_FEE',
}

export enum ChargeFrequency {
  ONE_TIME = 'ONE_TIME',
  MONTHLY = 'MONTHLY',
}

export interface Charge extends PropifyEntity {
  name: string;
  frequency: ChargeFrequency;
  amount: MonetaryAmount;
  type: LeaseChargeType;
}

export interface LeaseUtility extends PropifyEntity {
  leaseId: number;
  type: UtilityType;
  responsiblePartyRoleType: UtilityResponsiblePartyRoleType;
}

export enum LeaseType {
  MARKET = 'MARKET',
  SUBSIDY = 'SUBSIDY',
}

export interface Lease extends PropifyEntity {
  unitId: number;
  startDate: string;
  endDate: string;
  durationMonths: number;
  yearOverYearEscalator?: BigDecimal;
  parkingSpaces?: number;
  status?: LeaseStatus;
  type: LeaseType;
  countersigned?: boolean;
  paymentDueDay?: number;
  lateFeeMinimum?: MonetaryAmount;
  lateFeePerDay?: MonetaryAmount;
  lateFeePercentRent?: MonetaryAmount;
  lateFeeGracePeriod?: number;
  plusTax?: MonetaryAmount;
  residentId: number;
  foreignDbId?: number;
  foreignDbCode?: string;
  noticeDate?: string;
  promotionDetails?: string;
  charges?: Charge[];
  utilities?: LeaseUtility[];
  occupants?: Occupant[];
  vehicles?: Vehicle[];
  pets?: Pet[];
  securityDepositInsurance: boolean;
  sightUnseen: boolean;
  moveInCharges?: MonetaryAmount;
}

export interface LeaseUpdateRequest extends Omit<Lease, 'charges' | 'utilities'> {
  charges?: Charge[] | New<Charge>[];
  utilities?: LeaseUtility[] | New<LeaseUtility>[];
}

export enum LesseeType {
  PRIMARY = 'PRIMARY',
  COSIGNER = 'COSIGNER',
  GUARANTOR = 'GUARANTOR',
}

export interface Lessee extends PartyRole {
  applicationId: number;
  signed: boolean;
  type: LesseeType;
  firstName: string;
  middleName: string;
  lastName: string;
  nameSuffix: NameSuffix;
  leaseId: number;
  feeExempted: boolean;
}

export interface LesseeSearchResult extends Lessee {
  person: Person;
  leaseDocumentLinkPresent?: boolean;
  leaseDocumentLink?: string;
}

export interface LeaseSearchResult extends Lease {
  lessees: LesseeSearchResult[];
  unit: Unit;
  resident?: Resident;
  signaturesSigned: number;
  signaturesRequired: number;
  documentLink?: string;
  renewalId?: number;
  leasingId?: number;
  moveInDate?: string;
  moveOutDate?: string;
  residentStatus?: string;
  leaseDocumentTemplateCount?: number;
  hasContractFile: boolean;
}

export interface LeaseSearchParams {
  createTime?: string;
  startDate?: string;
  endDate?: string;
  residentStatus?: ResidentStatus | ResidentStatus[];
  status?: string;
  state?: string | string[];
  unitId?: number;
  leaseId?: number;
  residentId?: number | number[];
  propertyId?: number;
  unitApplicationId?: number;
  partyId?: number;
}

export interface LeaseFileRequest {
  startDate?: string;
  endDate?: string;
  fileType: LeaseFileType;
  description: string;
}

export enum LeaseFileType {
  ADDENDUM = 'ADDENDUM',
  AMENDMENT = 'AMENDMENT',
  LEASE_DOCUMENT_CONTRACT_FILE = 'LEASE_DOCUMENT_CONTRACT_FILE',
}
