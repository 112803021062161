import type { Model } from '@/models/connect';
import GlobalModel from '@/models/global';
import FormsModel from '@/pages/form/model';
import EmailModel from '@/pages/settings/email/model';
import PropertyManagersModel from '@/pages/settings/property-managers/models/property-managers';
import { initialize } from '@/utils/store';
import createSagaMiddleware from '@redux-saga/core';
import { configureStore } from '@reduxjs/toolkit';
import SettingsModel from './models/settings';

const models: Model[] = [PropertyManagersModel, FormsModel, GlobalModel, EmailModel, SettingsModel];

const { rootReducer, sagas } = initialize(models);

const sagaMiddleWare = createSagaMiddleware({
  onError(error: Error) {
    const e = error as any;
    e.preventDefault();
    // eslint-disable-next-line no-console
    console.error(e.message);
  },
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleWare],
});

sagas.forEach((saga) => sagaMiddleWare.run(saga));

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
