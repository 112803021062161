export enum Permission {
  INVITE_USER = 'INVITE_USER',
  UPDATE_USER = 'UPDATE_USER',
  READ_USER = 'READ_USER',
  CREATE_INSURANCE = 'CREATE_INSURANCE',
  UPDATE_INSURANCE = 'UPDATE_INSURANCE',
  READ_INSURANCE = 'READ_INSURANCE',
  READ_INSURANCE_FILE = 'READ_INSURANCE_FILE',
  UPDATE_SERVICE = 'UPDATE_SERVICE',
  READ_SERVICE = 'READ_SERVICE',
  CREATE_SERVICE_REGION = 'CREATE_SERVICE_REGION',
  UPDATE_SERVICE_REGION = 'UPDATE_SERVICE_REGION',
  READ_SERVICE_REGION = 'READ_SERVICE_REGION',
  CREATE_JOB = 'CREATE_JOB',
  UPDATE_JOB = 'UPDATE_JOB',
  READ_JOB = 'READ_JOB',
  APPROVE_JOB = 'APPROVE_JOB',
  CREATE_WORK_ORDER = 'CREATE_WORK_ORDER',
  UPDATE_WORK_ORDER = 'UPDATE_WORK_ORDER',
  READ_WORK_ORDER = 'READ_WORK_ORDER',
  ACCEPT_WORK_ORDER = 'ACCEPT_WORK_ORDER',
  CANCEL_WORK_ORDER = 'CANCEL_WORK_ORDER',
  RESOLVE_WORK_ORDER = 'RESOLVE_WORK_ORDER',
  REOPEN_WORK_ORDER = 'REOPEN_WORK_ORDER',
  ASSIGN_WORK_ORDER_VENDOR = 'ASSIGN_WORK_ORDER_VENDOR',
  CREATE_VISIT = 'CREATE_VISIT',
  UPDATE_VISIT = 'UPDATE_VISIT',
  READ_VISIT = 'READ_VISIT',
  READ_WORK_ORDER_ACTIVITY = 'READ_WORK_ORDER_ACTIVITY',
  CREATE_BID = 'CREATE_BID',
  DELETE_BID = 'DELETE_BID',
  CANCEL_BID = 'CANCEL_BID',
  UPDATE_BID = 'UPDATE_BID',
  READ_BID = 'READ_BID',
  ACCEPT_BID = 'ACCEPT_BID',
  REFUND_PAYMENT = 'REFUND_PAYMENT',
  READ_INVOICE = 'READ_INVOICE',
  APPROVE_INVOICE_1 = 'APPROVE_INVOICE_1',
  APPROVE_INVOICE_2 = 'APPROVE_INVOICE_2',
  APPROVE_INVOICE_3 = 'APPROVE_INVOICE_3',
  CREATE_PROPERTY = 'CREATE_PROPERTY',
  UPDATE_PROPERTY = 'UPDATE_PROPERTY',
  READ_DASHBOARD = 'READ_DASHBOARD',
  READ_TICKET_MENU = 'READ_TICKET_MENU',
  READ_COMMUNICATION_MENU = 'READ_COMMUNICATION_MENU',
  READ_SURVEY = 'READ_SURVEY',
  READ_TASK_MENU = 'READ_TASK_MENU',
  READ_LEASING = 'READ_LEASING',
  UPDATE_LEASE = 'UPDATE_LEASE',
  APPROVE_UNIT_APPLICATION = 'APPROVE_UNIT_APPLICATION',
  READ_UNIT_RENTAL_PRICING = 'READ_UNIT_RENTAL_PRICING',
  READ_LEAD = 'READ_LEAD',
  READ_SHOWING = 'READ_SHOWING',
  READ_APPLICATION = 'READ_APPLICATION',
  READ_PROSPECT_MENU = 'READ_PROSPECT_MENU',
  READ_FIELD_OPERATION_MENU = 'READ_FIELD_OPERATION_MENU',
  READ_GENERAL_WORK_ORDER = 'READ_GENERAL_WORK_ORDER',
  CREATE_VENDOR = 'CREATE_VENDOR',
  UPDATE_VENDOR = 'UPDATE_VENDOR',
  READ_VENDOR_MENU = 'READ_VENDOR_MENU',
  READ_RESIDENT_MENU = 'READ_RESIDENT_MENU',
  READ_RESIDENT = 'READ_RESIDENT',
  READ_MOVE_IN = 'READ_MOVE_IN',
  READ_RENEWAL = 'READ_RENEWAL',
  READ_MOVE_OUT = 'READ_MOVE_OUT',
  READ_COLLECTION = 'READ_COLLECTION',
  READ_PROPERTY = 'READ_PROPERTY',
  READ_PROPERTY_GROUP = 'READ_PROPERTY_GROUP',
  CREATE_PROPERTY_GROUP = 'CREATE_PROPERTY_GROUP',
  UPDATE_PROPERTY_GROUP = 'UPDATE_PROPERTY_GROUP',
  READ_LISTING_PROCESS = 'READ_LISTING_PROCESS',
  CREATE_UTILITY = 'CREATE_UTILITY',
  UPDATE_UTILITY = 'UPDATE_UTILITY',
  READ_UTILITY = 'READ_UTILITY',
  CREATE_COMMUNITY = 'CREATE_COMMUNITY',
  UPDATE_COMMUNITY = 'UPDATE_COMMUNITY',
  READ_ONBOARDING_PROCESS = 'READ_ONBOARDING_PROCESS',
  READ_DISPOSITION = 'READ_DISPOSITION',
  READ_SMART_HOME_MENU = 'READ_SMART_HOME_MENU',
  READ_SYSTEM = 'READ_SYSTEM',
  CREATE_SYSTEM = 'CREATE_SYSTEM',
  UPDATE_SYSTEM = 'UPDATE_SYSTEM',
  READ_SYSTEM_INSTALLATION = 'READ_SYSTEM_INSTALLATION',
  READ_SYSTEM_VACANT_UNIT = 'READ_SYSTEM_VACANT_UNIT',
  READ_SYSTEM_OCCUPIED_UNIT = 'READ_SYSTEM_OCCUPIED_UNIT',
  READ_SETTING_MENU = 'READ_SETTING_MENU',
  READ_PROPERTY_MENU = 'READ_PROPERTY_MENU',
  READ_MAINTENANCE_JOB_MENU = 'READ_MAINTENANCE_JOB_MENU',
  READ_TURN_MENU = 'READ_TURN_MENU',
  READ_RECURRING_FIELD_OPS_MENU = 'READ_RECURRING_FIELD_OPS_MENU',
  READ_COMMUNITY_MENU = 'READ_COMMUNITY_MENU',
  READ_REHAB_MENU = 'READ_REHAB_MENU',
  UPDATE_UNIT = 'UPDATE_UNIT',
  READ_EMAIL_TEMPLATE = 'READ_EMAIL_TEMPLATE',
  CREATE_EMAIL_TEMPLATE = 'CREATE_EMAIL_TEMPLATE',
  UPDATE_EMAIL_TEMPLATE = 'UPDATE_EMAIL_TEMPLATE',
  CREATE_USER_ROLE_TYPE = 'CREATE_USER_ROLE_TYPE',
  BULK_UPDATE_PRICING_CADENCE_DAYS = 'BULK_UPDATE_PRICING_CADENCE_DAYS',
  UPDATE_CALL_CENTER_AGENT_INFORMATION_OWN_ACCOUNT = 'UPDATE_CALL_CENTER_AGENT_INFORMATION_OWN_ACCOUNT',
  READ_CALL_CENTER_AGENT_INFORMATION_OTHER_ACCOUNT = 'READ_CALL_CENTER_AGENT_INFORMATION_OTHER_ACCOUNT',
  UPDATE_CALL_CENTER_AGENT_INFORMATION_OTHER_ACCOUNT = 'UPDATE_CALL_CENTER_AGENT_INFORMATION_OTHER_ACCOUNT',
  READ_CALL_CENTER_DASHBOARD = 'READ_CALL_CENTER_DASHBOARD',
  CREATE_CONFERENCE_PARTICIPANT_MANAGER = 'CREATE_CONFERENCE_PARTICIPANT_MANAGER',
  READ_CHARGE_TEMPLATE = 'READ_CHARGE_TEMPLATE',
  CREATE_CHARGE_TEMPLATE = 'CREATE_CHARGE_TEMPLATE',
  UPDATE_CHARGE_TEMPLATE = 'UPDATE_CHARGE_TEMPLATE',
  BULK_UPDATE_UNIT_CHARGE_TEMPLATE = 'BULK_UPDATE_UNIT_CHARGE_TEMPLATE',
  READ_PUBLIC_ACCOUNT_AGREEMENT = 'READ_PUBLIC_ACCOUNT_AGREEMENT',
  CREATE_PUBLIC_ACCOUNT_AGREEMENT = 'CREATE_PUBLIC_ACCOUNT_AGREEMENT',
  UPDATE_PUBLIC_ACCOUNT_AGREEMENT = 'UPDATE_PUBLIC_ACCOUNT_AGREEMENT',
  READ_CONTACT = 'READ_CONTACT',
  READ_ATTORNEY = 'READ_ATTORNEY',
  READ_REAL_ESTATE_AGENT = 'READ_REAL_ESTATE_AGENT',
  READ_MY_TASKS = 'READ_MY_TASKS',
  READ_MY_PROCESS_TASKS = 'READ_MY_PROCESS_TASKS',
  READ_ONBOARDING_TASKS = 'READ_ONBOARDING_TASKS',
  READ_RENOVATION_TASKS = 'READ_RENOVATION_TASKS',
  READ_LISTING_TASKS = 'READ_LISTING_TASKS',
  READ_LEASING_TASKS = 'READ_LEASING_TASKS',
  READ_MOVE_IN_TASKS = 'READ_MOVE_IN_TASKS',
  READ_MOVE_OUT_TASKS = 'READ_MOVE_OUT_TASKS',
  READ_TURN_TASKS = 'READ_TURN_TASKS',
  READ_DISPOSITIONS_TASKS = 'READ_DISPOSITIONS_TASKS',
  READ_EVICTIONS_TASKS = 'READ_EVICTIONS_TASKS',
  READ_MY_SHOWINGS = 'READ_MY_SHOWINGS',
  READ_PROSPECT_APPLICATION = 'READ_PROSPECT_APPLICATION',
  READ_MY_WORK_ORDERS = 'READ_MY_WORK_ORDERS',
  READ_TURN_WORK_ORDERS = 'READ_TURN_WORK_ORDERS',
  READ_TURN_JOBS = 'READ_TURN_JOBS',
  READ_REHAB_WORK_ORDER = 'READ_REHAB_WORK_ORDER',
  READ_REHAB_JOBS = 'READ_REHAB_JOBS',
  READ_MAINTENANCE_JOB_WORK_ORDERS = 'READ_MAINTENANCE_JOB_WORK_ORDERS',
  READ_RECURRING_WORK_ORDERS_PROPERTIES = 'READ_RECURRING_WORK_ORDERS_PROPERTIES',
  READ_RECURRING_SERVICE_PROVIDERS = 'READ_RECURRING_SERVICE_PROVIDERS',
  READ_RECURRING_WORK_ORDER_TEMPLATES = 'READ_RECURRING_WORK_ORDER_TEMPLATES',
  READ_FLOOR_PLANS_PAGE = 'READ_FLOOR_PLANS_PAGE',
  READ_UNITS_PAGE = 'READ_UNITS_PAGE',
  READ_COMMUNITIES_PAGE = 'READ_COMMUNITIES_PAGE',
  UPDATE_NSF_THRESHOLD = 'UPDATE_NSF_THRESHOLD',
}

export interface PermissionItem {
  displayName: string;
  description: string;
  permissionSubcategory: string;
  value: Permission;
  permissions: Permission[];
}
