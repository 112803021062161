import SectionSplashScreen from '@/components/PageLoading/SplashSection';
import { store } from '@/store';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { usePermissionsRouter } from './hooks/usePermissionsRouter';

const App = () => {
  const { permissionsRouter, isLoading } = usePermissionsRouter();

  if (isLoading) {
    return <SectionSplashScreen />;
  }

  return (
    <Provider store={store}>
      <RouterProvider router={permissionsRouter} />
    </Provider>
  );
};

export default App;
