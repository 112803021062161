import { apiClient } from '@/services/apiClient';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import isUndefined from 'lodash/isUndefined';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReconnectingEventSource from 'reconnecting-eventsource';
import type { SWRConfiguration, SWRResponse } from 'swr';
import useSWR from 'swr';
import type { BareFetcher, PublicConfiguration } from 'swr/dist/_internal';
import { getAccessToken } from './authority';
import { handleError } from './utils';

export const crudService = {
  get: <T>(url: string, config?: AxiosRequestConfig) =>
    apiClient.get<T, AxiosResponse<T>>(url, config).then((response) => response.data),

  post: <T>(obj: any, url: string, config?: AxiosRequestConfig) =>
    apiClient.post<T, AxiosResponse<T>>(url, obj, config).then((response) => response.data),

  put: <T>(obj: any, url: string, config?: AxiosRequestConfig) =>
    apiClient.put<T, AxiosResponse<T>>(url, obj, config).then((response) => response.data),

  patch: <T>(obj: any, url: string, config?: AxiosRequestConfig) =>
    apiClient.patch<T, AxiosResponse<T>>(url, obj, config).then((response) => response.data),

  delete: <T>(url: string, config?: AxiosRequestConfig) =>
    apiClient.delete<T, AxiosResponse<T>>(url, config).then((response) => response.data),
};

export type UseFetchConfiguration<
  Data,
  Error,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  KeyParameters extends any[],
  Fn extends (...args: KeyParameters) => Promise<Data>,
> = SWRConfiguration<Data, Error> & {
  fetcher?: Fn;
  errorMessage?: string;
};

export type UseFetchResponse<Data, Error> = SWRResponse<Data, Error>;

export const useFetch = <
  Data,
  Error,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FetchParameters extends any[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  KeyParameters extends any[],
>(
  config: UseFetchConfiguration<
    Data,
    Error,
    KeyParameters,
    Fetcher<Data, Error, FetchParameters, KeyParameters>
  >,
  ...params: KeyParameters
): UseFetchResponse<Data, Error> => {
  const newKey = config.fetcher?.key ? config.fetcher.key(...params) : null;
  const jsonNewKey = JSON.stringify(newKey);

  const [key, setKey] = useState<typeof newKey>(null);
  const controllerRef = useRef<AbortController>();

  useEffect(() => {
    setKey(newKey);
  }, [jsonNewKey]);

  useEffect(() => {
    return () => {
      controllerRef.current?.abort();
    };
  }, []);

  const fetcher = useCallback(
    (args: FetchParameters) => {
      const newController = new AbortController();
      controllerRef.current?.abort();
      controllerRef.current = newController;

      return config
        .fetcher!.fetch(args, newController.signal)
        .then((data) => {
          // Check if aborted and throw an error to avoid caching invalid data
          if (newController.signal.aborted) {
            throw new Error('Fetch Aborted');
          }
          return data;
        })
        .catch((error) => {
          if (newController.signal.aborted) {
            throw error;
          }
          handleError(error, {
            displayToast: !!config?.errorMessage,
            toastMessage: config?.errorMessage,
            rethrowError: true,
          });
          throw error;
        });
    },
    [config.errorMessage, config.fetcher],
  );

  const onErrorRetry: PublicConfiguration<Data, Error, BareFetcher<Data>>['onErrorRetry'] = (
    err: any,
    _,
    configuration,
    revalidate,
    opts,
  ) => {
    // Do not retry when status code is 404
    // See https://swr.vercel.app/docs/error-handling#error-retry
    if (err?.status === 404) {
      return;
    }

    // Default implementation copied from original `useSWR` hook
    // See https://github.com/vercel/swr/blob/6da84a375f59ec88b7dc0eedc27ed8ab1e56066a/src/_internal/utils/config.ts#L16-L38
    const maxRetryCount = configuration.errorRetryCount;
    const currentRetryCount = opts.retryCount;

    // cspell:ignore backoff
    // Exponential backoff
    const timeout =
      ~~((Math.random() + 0.5) * (1 << (currentRetryCount < 8 ? currentRetryCount : 8))) *
      configuration.errorRetryInterval;

    if (!isUndefined(maxRetryCount) && currentRetryCount > maxRetryCount) {
      return;
    }

    setTimeout(revalidate, timeout, opts);
  };

  return useSWR(key, {
    revalidateOnFocus: false,
    onErrorRetry,
    ...config,
    fetcher,
  });
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface Fetcher<Result, _, FetchParameters extends any[], KeyParameters extends any[]> {
  (...args: KeyParameters): Promise<Result>;
  fetch: (args: FetchParameters, signal?: AbortSignal) => Promise<Result>;
  key: (...args: KeyParameters) => FetchParameters;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AxiosFetcher<Result, KeyParameters extends any[]> = Fetcher<
  Result,
  AxiosError,
  Parameters<typeof crudService.get>,
  KeyParameters
>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getAxiosFetcher = <Result, KeyParameters extends any[], ReturnType = Result>(
  key: AxiosFetcher<Result, KeyParameters>['key'],
  postProcessor?: (result: Promise<Result>) => Promise<ReturnType>,
) => {
  const fetcher: AxiosFetcher<Result | ReturnType, KeyParameters> = (...args: KeyParameters) =>
    fetcher.fetch(key(...args));

  fetcher.fetch = (...[args, signal]: Parameters<AxiosFetcher<Result, KeyParameters>['fetch']>) => {
    const [url, params] = args;
    const queryResult = crudService.get<Result>(url, {
      ...params,
      signal: params?.signal ?? signal,
    });
    return postProcessor ? postProcessor(queryResult) : queryResult;
  };

  fetcher.key = (...args: KeyParameters) => {
    const r = key(...args);
    const params = r[1];

    if (params !== undefined && Object.entries(params).length === 0) {
      r.pop();
    }

    return r;
  };

  return fetcher;
};

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const retryOnFail = async <T>(
  fn: () => T | Promise<T>,
  attemptsDelays: number[],
): Promise<T> => {
  try {
    return await fn();
  } catch (error) {
    handleError(error, { displayToast: false });
    if (attemptsDelays.length > 0) {
      await delay(attemptsDelays[0]);
      return retryOnFail(fn, attemptsDelays.slice(1));
    }

    throw error;
  }
};

export const getEvents = (uri: string): EventSource =>
  new ReconnectingEventSource(`/rest${uri}?accessToken=${encodeURIComponent(getAccessToken()!)}`);
